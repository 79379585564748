type StorageType = 'localStorage' | 'sessionStorage'

const localStorageFallback = {}
const sessionStorageFallback = {}

function storageServiceFor(storage: Storage | undefined, fallback) {
  let storageAvailable = false
  try {
    const x = 'storageTest'
    storage.setItem(x, x)
    storage.removeItem(x)
    storageAvailable = true
  } catch (e) {
    storageAvailable = false
  }

  return {
    setItem(key, value) {
      if (storageAvailable) {
        storage.setItem(key, value)
      } else {
        fallback[key] = value
      }
    },
    getItem(key) {
      return storageAvailable ? storage.getItem(key) : fallback[key]
    },
    removeItem(key) {
      if (storageAvailable) {
        storage.removeItem(key)
      } else {
        fallback[key] = null
      }
    },
    clear() {
      if (storageAvailable) {
        storage.clear()
      } else {
        Object.keys(fallback).forEach((key) => {
          delete fallback[key]
        })
      }
    },
  }
}

const getStorageType = (storageType: StorageType): Storage | undefined => {
  if (typeof window !== 'undefined') {
    try {
      return storageType === 'localStorage' ? localStorage : sessionStorage
    } catch {
      return undefined
    }
  }
}

export const storageService: StorageService = storageServiceFor(getStorageType('localStorage'), localStorageFallback)

export const sessionStorageService: StorageService = storageServiceFor(
  getStorageType('sessionStorage'),
  sessionStorageFallback,
)

export type StorageService = ReturnType<typeof storageServiceFor>
