import { h } from 'preact'
import { i18n } from 'i18n/i18n'

const ErrorSpinner = () => {
  return (
    <div className="spinner">
      <div className="spinner__holder">
        <div className="spinner__animation" />
        <p className="spinner__text">{`${i18n('calculator.spinnerError')}`}</p>
      </div>
    </div>
  )
}

export default ErrorSpinner
