import { h } from 'preact'
import { StateUpdater } from 'preact/hooks'
import { CurrencyValueForm } from 'models/apps'
import { sessionStorageService } from 'services/storage'
import { debounce } from 'utils/functions'

declare const window: any

interface CalculatorInputProps {
  value: CurrencyValueForm
  setValue: StateUpdater<CurrencyValueForm>
}

const CalculatorInput = ({ value, setValue }: CalculatorInputProps) => {
  const isCheckValueCorrect = (inputValue: string): boolean => !['0', '0,', '', '0,00', '0,0'].includes(inputValue)

  const setInputValue = (inputValue: string): void => {
    debounce(() => {
      setValue({
        currentValue: inputValue,
        isValueCorrect: isCheckValueCorrect(inputValue),
      })
    }, 250)

    sessionStorageService.setItem('preactCalculatorInputValue', inputValue)
  }

  const getCurrentSettings = () => {
    const direction = document
      .querySelector<HTMLDivElement>('[data-calculator-direction]')
      .getAttribute('data-value') as string
    const amountInput = document.querySelector<HTMLInputElement>('[data-calculator-value]').value as string
    const amount = amountInput === '' ? '0' : String(amountInput).replace(',', '.')

    const to = document
      .querySelector<HTMLDivElement>('[data-calculator-currency="sell"]')
      .getAttribute('data-value') as string
    const from = document
      .querySelector<HTMLDivElement>('[data-calculator-currency="buy"]')
      .getAttribute('data-value') as string

    return {
      direction,
      amount,
      from,
      to,
    }
  }

  const inputValidation = (event): void | string => {
    const value = (event.target as HTMLInputElement).value

    if (!value) {
      return ''
    }

    const transformedInput: string[] = value
      .toString()
      .replace(',', '.')
      .replace(/[^0-9.0-9{2}]+/g, '')
      .split('.')
      .slice(0, 2)

    if (transformedInput.length > 1 && !transformedInput[0]) {
      transformedInput[0] = '0'
    }

    if (transformedInput[1]) {
      transformedInput[1] = transformedInput[1].substr(0, 2)
    }

    if (transformedInput[0].startsWith('0') && transformedInput[0].length > 1) {
      transformedInput[0] = transformedInput[0].slice(1)
    }

    setInputValue(transformedInput.join(','))

    const currentSettings = getCurrentSettings()

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'savings_calculator',
      calculator_type: currentSettings.direction === 'buy' ? 'Kupno' : 'Sprzedaż',
      calculator_value: currentSettings.amount,
      calculator_value_from: currentSettings.from,
      calculator_value_to: currentSettings.to,
    })
  }

  return (
    <div className="hero-form__options-holder hero-form__options-holder--center">
      <input
        className="hero-form__input-label"
        type="tel"
        value={value.currentValue}
        onInput={inputValidation}
        maxLength={6}
        aria-label="Kwota"
        data-calculator-value
      />
    </div>
  )
}

export default CalculatorInput
