import { h } from 'preact'
import BanksSelect from './banks-select'
import { BankData } from 'src/assets/scripts/models/market'

interface SingleChartProps {
  bankName: string
  isBankSelect?: boolean
  handleSetClickedBank?: any
  offerPercentage: string
  banksData: BankData[]
  isFirstRow?: boolean
}

const SingleChart = ({
  handleSetClickedBank,
  isBankSelect,
  bankName,
  offerPercentage,
  banksData,
  isFirstRow,
}: SingleChartProps) => {
  const addWtClass = isFirstRow ? 'is-wt' : ''

  return (
    <div className="calculator-chart__single-chart-holder">
      {isBankSelect ? (
        <BanksSelect
          handleSetClickedBank={handleSetClickedBank}
          banksData={banksData}
          bankName={bankName}
        />
      ) : (
        <p className={`calculator-chart__chart-title ${addWtClass}`}>{bankName}</p>
      )}
      <div className="calculator-chart__chart-holder">
        <div
          className={`calculator-chart__chart-percetage-line ${addWtClass}`}
          style={{ width: `${offerPercentage}%` }}
        />
      </div>
    </div>
  )
}

export default SingleChart
