import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { promiseWrapper } from 'utils/promise'
import CalculatorInput from './input-element'
import MainChartView from './main-chart'
import CalculatorDirectionSelect from './direction-select'
import CalculatorCurrencySelect from './currency-select'
import ErrorSpinner from '../../common/error-spinner'
import { CalculatorDirection, CurrencyValueForm, CalculatorSavings } from 'models/apps'
import { MarketEstimate, BankData } from 'src/assets/scripts/models/market'
import { initI18n, i18n } from 'i18n/i18n'
import { STATIC_CALCULATOR_SAVINGS_DATA, STATIC_EXCHANGE_RATES } from 'static-data/frontpage-calculator-fake-data'
import { validateCurrencyValue } from 'app-utils/validate-storage-value'

declare const USER_BASE_URL_WT: string
const MARKET_ESTIMATE_API = '/api/public/marketEstimate'
const DEFAULT_CURRENCY_VALUE = '1000'
const SUPPORTED_CURRENCIES_PAIRS_API = '/api/public/settings?keys[]=market-currency-pairs'

initI18n()

const MainCalculatorView = () => {
  const [isErrorVisible, setErrorVisible] = useState<boolean>(false)
  const [isCurrencySupported, setCurrencySupported] = useState<boolean>(true)
  const [supportedCurrenciesPairs, setSupportedCurrenciesPairs] = useState<string[]>(null)
  const [currentOffer, setCurrentOffer] = useState<BankData[]>(STATIC_EXCHANGE_RATES)
  const [savings, setSavings] = useState<CalculatorSavings>(STATIC_CALCULATOR_SAVINGS_DATA)
  const [direction, setDirection] = useState<CalculatorDirection>('BUY')
  const [buyCurrency, setBuyCurrency] = useState<string>('EUR')
  const [sellCurrency, setSellCurrency] = useState<string>('PLN')
  const [value, setValue] = useState<CurrencyValueForm>({
    currentValue: DEFAULT_CURRENCY_VALUE,
    isValueCorrect: true,
  })

  const getAllSupportedCombinations = (supportedPairs: string[]): void => {
    const allPairsCombinations = []
    supportedPairs.forEach((currency) => {
      const [baseCurrency, counterCurrency] = [currency.slice(3), currency.slice(0, 3)]
      allPairsCombinations.push(baseCurrency + counterCurrency, counterCurrency + baseCurrency)
    })
    setSupportedCurrenciesPairs(allPairsCombinations)
  }

  useEffect(() => {
    setValue({ currentValue: validateCurrencyValue(), isValueCorrect: true })

    const fetchSupportedCurrency = async (): Promise<void> => {
      try {
        const response = await promiseWrapper<string[], undefined>(
          `${USER_BASE_URL_WT}${SUPPORTED_CURRENCIES_PAIRS_API}`,
          'GET',
        )
        getAllSupportedCombinations(response['market-currency-pairs'].pairs)
      } catch {
        setErrorVisible(true)
      }
    }
    fetchSupportedCurrency()
  }, [])

  useEffect(() => {
    if (!value.isValueCorrect || !supportedCurrenciesPairs) {
      return
    }

    if (!isUserCurrencyPairSupported()) return
    setErrorVisible(false)
    const fetchData = async (): Promise<void> => {
      try {
        const response = await promiseWrapper<MarketEstimate, undefined>(buildLink(), 'GET')
        setCurrentOffer(response['exchange_offers'])
        setSavings(
          response['one_time_saving']
            ? { one_time_saving: response['one_time_saving'], yearly_savings: response['yearly_savings'] }
            : null,
        )
      } catch {
        setErrorVisible(true)
      }
    }
    fetchData()

    const interval = setInterval(fetchData, 30 * 1000)

    return () => clearInterval(interval)
  }, [direction, buyCurrency, sellCurrency, value, supportedCurrenciesPairs])

  const isUserCurrencyPairSupported = (): boolean => {
    const userPair = `${buyCurrency}${sellCurrency}`
    const isUserPairSupported = !!supportedCurrenciesPairs.find((pair) => pair === userPair)
    isUserPairSupported ? setCurrencySupported(true) : setCurrencySupported(false)
    return isUserPairSupported
  }

  const buildLink = (): string => {
    return `${USER_BASE_URL_WT}${MARKET_ESTIMATE_API}/${direction}/${value.currentValue}/${buyCurrency}/${sellCurrency}`
  }

  return (
    <div className="hero-form">
      <div className="hero-form__main-section">
        <p className="hero-form__title">{i18n('calculator.title')}</p>
        <div className="hero-form__holder">
          <div className="hero-form__flex-items">
            <div>{i18n('calculator.rangeFrom')}</div>
            <CalculatorDirectionSelect
              direction={direction}
              handleSetDirection={setDirection}
            />
          </div>
          <CalculatorInput
            value={value}
            setValue={setValue}
          />
          <div className="hero-form__flex-items">
            <CalculatorCurrencySelect
              isBuyComponent={true}
              buyCurrency={buyCurrency}
              sellCurrency={sellCurrency}
              handleSetBuyCurrency={setBuyCurrency}
              handleSetSellCurrency={setSellCurrency}
            />
            <p> {i18n('calculator.forBuy')} </p>
            <CalculatorCurrencySelect
              isBuyComponent={false}
              buyCurrency={buyCurrency}
              sellCurrency={sellCurrency}
              handleSetBuyCurrency={setBuyCurrency}
              handleSetSellCurrency={setSellCurrency}
            />
          </div>
        </div>
        {!isCurrencySupported && <p className="hero-form__error-info">{i18n('calculator.unsupportedPair')}</p>}
      </div>
      {
        <MainChartView
          incomingBanksData={currentOffer}
          savings={savings}
          sellCurrency={sellCurrency}
          direction={direction}
          value={value.currentValue}
          buyCurrency={buyCurrency}
        />
      }
      {isErrorVisible && <ErrorSpinner />}
    </div>
  )
}

export default MainCalculatorView
