import { h } from 'preact'
import { CalculatorSavings } from 'models/apps'
import { formatNumber } from 'utils/format-number'
import { i18n } from 'i18n/i18n'

interface SavingsProps {
  sellCurrency: string
  savings: CalculatorSavings
}

const Savings = ({ sellCurrency, savings }: SavingsProps) => {
  return (
    <div className="calculator-chart__savings-info">
      <p>
        {i18n('calculator.savingsMonthly')}
        <span className="is-bold">
          {formatNumber(savings.one_time_saving, 2)} {sellCurrency}
        </span>
        , {i18n('calculator.conjunction')}
        <span className="is-bold">
          {formatNumber(savings.yearly_savings, 2)} {sellCurrency}
        </span>
        {i18n('calculator.savingsYearly')}{' '}
      </p>
    </div>
  )
}

export default Savings
