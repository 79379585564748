export default {
  highcharts: {
    decimalPoint: ',',
    downloadJPEG: 'Download a JPG image',
    downloadPDF: 'Download a PDF document',
    downloadPNG: 'Download a PNG image',
    downloadSVG: 'Download an SVG image',
    exportButtonTitle: 'Export',
    forexRate: 'Average FOREX rate',
    loading: 'Loading...',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    printButtonTitle: 'Print',
    rangeSelectorFrom: 'From',
    rangeSelectorTo: 'To',
    rangeSelectorZoom: 'Zoom',
    rateTypes: {
      ask: 'The best sale offer',
      bid: 'The best buy offer',
      forex: 'Average exchange rate in the world',
    },
    resetZoom: 'Reset',
    resetZoomTitle: 'Reset',
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    thousandsSep: ' ',
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    wtBestAsk: 'The best sale offer',
    wtBestBid: 'The best buy offer',
  },
  calculator: {
    title: 'Calculate savings in Walutomat',
    rangeFrom: 'I want to',
    forBuy: 'for',
    forSell: 'to',
    directionBuy: 'Buying',
    directionSell: 'Selling',
    payText: 'you will pay',
    sellText: 'you will receive',
    buySelect: 'buy',
    sellSelect: 'sell',
    rateText: 'Rate',
    amountText: 'Amount',
    includes: 'Includes',
    commission: '{{commission}}% commission',
    howItWorks: '',
    banksComapreOpen: 'Compare with others',
    banksComapreClose: 'Collapse the list',
    savingsMonthly: 'You save even once with Walutomat',
    savingsYearly: 'replacing throughout the year, every month.',
    conjunction: 'and',
    serverError: 'Server communication error',
    unsupportedPair: 'We currently do not offer rates for the selected currency pair.',
    infoError: {
      exchange: 'At Walutomat.pl you can directly exchange',
      transactionFee:
        'and back. Such transaction at the bank is associated with a high fee, usually reaching even 5-10% of the exchange amount.',
    },
    spinnerError: 'There was a problem with downloading data',
  },
}
