import { h } from 'preact'
import { useState, StateUpdater } from 'preact/hooks'
import SingleOption from './single-option'

interface CalculatorCurrencySelectProps {
  isBuyComponent: boolean
  buyCurrency: string
  sellCurrency: string
  handleSetBuyCurrency: StateUpdater<string>
  handleSetSellCurrency: StateUpdater<string>
}

const FIXED_CURRENCY_LIST: string[] = ['PLN', 'EUR', 'USD', 'CHF', 'GBP', 'CZK', 'DKK', 'NOK', 'SEK']

const CalculatorCurrencySelect = ({
  isBuyComponent,
  buyCurrency,
  sellCurrency,
  handleSetBuyCurrency,
  handleSetSellCurrency,
}: CalculatorCurrencySelectProps) => {
  const [isSelectVisible, setSelectVisibility] = useState<boolean>(false)

  const changeSelectVisibiliy = (): void => {
    setSelectVisibility(!isSelectVisible)
  }

  const setSelectedCurrency = (selectedOption: string): void => {
    if (isBuyComponent) {
      handleSetBuyCurrency(selectedOption)
      selectedOption === sellCurrency ? handleSetSellCurrency(buyCurrency) : ''
    } else {
      handleSetSellCurrency(selectedOption)
      selectedOption === buyCurrency ? handleSetBuyCurrency(sellCurrency) : ''
    }
  }

  return (
    <div className="hero-form__options-holder">
      <div
        className="hero-form__select"
        onClick={changeSelectVisibiliy}
        data-calculator-currency={isBuyComponent ? 'buy' : 'sell'}
        data-value={isBuyComponent ? buyCurrency : sellCurrency}
      >
        <p>{isBuyComponent ? buyCurrency : sellCurrency}</p>
        <span className="hero-form__select-chevron" />
      </div>
      {isSelectVisible && (
        <div
          className="hero-form__options-list"
          onClick={changeSelectVisibiliy}
        >
          {FIXED_CURRENCY_LIST.map((option) => (
            <SingleOption
              key={option}
              handleSetClickedOption={setSelectedCurrency}
              singleOption={option}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CalculatorCurrencySelect
