import {
  AllAllowedCookies,
  AllAllowedCookiesForPanel,
  AllowedFunctionalCookies,
  AllowedFunctionalCookiesForPanel,
  AllowedMarketingCookies,
  AllowedParam,
} from '../models/cookies'

export const allowedMarketingCookies: AllowedMarketingCookies[] = [
  'refererCode',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_content',
  'ref',
]

export const allowedFunctionalCookies: AllowedFunctionalCookies[] = ['language', 'promo_popup', 'promoPopup__showed']

export const allAllowedCookies: AllAllowedCookies[] = [...allowedMarketingCookies, ...allowedFunctionalCookies]

export const allowedFunctionalCookiesForPanel: AllowedFunctionalCookiesForPanel[] = ['language']
export const allAllowedCookiesForPanel: AllAllowedCookiesForPanel[] = [
  ...allowedMarketingCookies,
  ...allowedFunctionalCookiesForPanel,
]

export const allowedParamsRefvisIds: AllowedParam[] = [
  { name: 'utm_source', type: 'string' },
  { name: 'utm_campaign', type: 'string' },
  { name: 'utm_medium', type: 'string' },
  { name: 'utm_term', type: 'string' },
  { name: 'utm_content', type: 'string' },
  { name: 'ref', type: 'string' },
]

export const allowedParamsRefvisIdsAndReferCode: AllowedParam[] = [
  ...allowedParamsRefvisIds,
  { name: 'refererCode', type: 'string' },
]

export const allowedParamsIds: AllowedParam[] = [
  { name: 'promo_popup', type: 'boolean' },
  { name: 'promoPopup__showed', type: 'boolean' },
]
