export default {
  calculator: {
    title: 'Розрахуйте свої заощадження у Walutomat',
    rangeFrom: 'Хочу',
    forBuy: 'за',
    forSell: 'на',
    directionBuy: 'При покупці',
    directionSell: 'Продаючи',
    payText: 'Ти заплатиш',
    sellText: 'ви отримаєте',
    rateText: 'Курс',
    buySelect: 'купити',
    sellSelect: 'продати',
    amountText: 'Вартість',
    includes: 'Включно',
    commission: 'з комісією {{commission}}%',
    howItWorks: '',
    banksComapreOpen: 'Порівняти з іншими',
    banksComapreClose: 'Згорнути список',
    savingsMonthly: 'З Walutomat ви можете заощадити до',
    savingsYearly: 'обмінюючи цілий рік, щомісяця.',
    conjunction: 'за один раз і',
    serverError: "Помилка зв'язку з сервером.",
    unsupportedPair: 'На даний момент ми не надаємо курси для вибраної пари валют.',
    infoError: {
      exchange: 'На Walutomat.pl ви можете безпосередньо обміняти',
      transactionFee:
        'і навпаки. Така транзакція в банку пов’язана з високою комісією, найчастіше сягає навіть 5-10% від суми обміну.',
    },
    spinnerError: 'Під час завантаження даних виникла проблема',
  },
}
