import { jsEscape } from 'utils/prevent-xss-attack'
import { AllowedParam } from 'models/cookies'
import cookieService, { doesUrlContainParam, getParamsFromUrl, validateParam } from './cookie-service'

export function getValidParamsToStore(
  allowedParamsIds: AllowedParam[],
  decodedParams?: Record<string, string>,
): Record<string, string> {
  const paramsToStore = decodedParams || getParamsFromUrl()

  return Object.keys(paramsToStore).reduce((acc, paramName) => {
    const allowedParam = allowedParamsIds.find((ap) => ap.name === paramName)
    const paramValue = paramsToStore[paramName]

    if (allowedParam && validateParam(allowedParam, paramValue)) {
      acc[paramName] = paramValue
    } else {
      console.warn(`Invalid or unauthorized parameter: ${paramName}`)
    }

    return acc
  }, {} as Record<string, string>)
}

export function storeSessionMarketingItemsAsCookies(allowedParamsIds: AllowedParam[]): void {
  const items = sessionStorageService.getAllItems()
  Object.keys(items).forEach((key) => {
    const allowedParam = allowedParamsIds.find((ap) => ap.name === key)
    const paramName = items[key]
    if (allowedParam && validateParam(allowedParam, paramName)) {
      cookieService.setItem(key, paramName, cookieService.maximalCookieTTL)
    } else {
      console.warn(`Invalid or unauthorized parameter: ${key}`)
    }
  })
}

const sessionStorageService = {
  storage: typeof window !== 'undefined' ? window.sessionStorage : null,
  setItem(key: string, value: string): void {
    const escapedKey = jsEscape(key)
    const escapedValue = jsEscape(value)
    this.storage.setItem(escapedKey, escapedValue)
  },
  getItem(key: string): string | null {
    return this.storage.getItem(jsEscape(key))
  },
  removeItem(key: string): void {
    this.storage.removeItem(jsEscape(key))
  },
  removeAll(): void {
    this.storage.clear()
  },
  removeItems(allowedParams: AllowedParam[]): void {
    const allItems = this.getAllItems()
    allowedParams.forEach((param) => {
      if (allItems[param.name]) {
        this.removeItem(param.name)
      }
    })
  },
  saveCookiesFromURL: (allowedParamsIds: AllowedParam[], decodedParams?: Record<string, string>): void => {
    const validParamsToStore = getValidParamsToStore(allowedParamsIds, decodedParams)

    Object.keys(validParamsToStore).forEach((paramName) => {
      if (doesUrlContainParam(paramName, validParamsToStore)) {
        const cookieName = paramName === 'xchangerId' ? 'xchanger_id' : paramName
        sessionStorageService.setItem(cookieName, validParamsToStore[paramName])
      }
    })
  },
  getAllItems(): Record<string, string> {
    const cookies: Record<string, string> = {}
    for (const [key, value] of Object.entries(sessionStorage)) {
      cookies[key] = value
    }
    return cookies
  },
}

export default sessionStorageService
