import { h } from 'preact'

interface SingleOptionProps {
  bankId?: string
  singleOption: string
  handleSetClickedOption: (option: string) => void
}

const SingleOption = ({ handleSetClickedOption, bankId, singleOption }: SingleOptionProps) => {
  const setClickedOption = () => {
    bankId === undefined ? handleSetClickedOption(singleOption) : handleSetClickedOption(bankId)
  }

  return (
    <div
      className="hero-form__single-option"
      onClick={setClickedOption}
    >
      {singleOption}
    </div>
  )
}

export default SingleOption
