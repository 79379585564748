import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import InfoElement from './info-element'
import SingleBankRow from './single-bank-row'
import { CalculatorSavings } from 'models/apps'
import { BankData } from 'src/assets/scripts/models/market'
import InteractiveElements from './interactive-chart-elements'
import { formatNumber } from 'utils/format-number'
import { i18n } from 'i18n/i18n'
import { STATIC_EXCHANGE_RATES } from 'static-data/frontpage-calculator-fake-data'

interface MainChartViewProps {
  incomingBanksData: BankData[]
  direction: string
  value: string
  buyCurrency: string
  sellCurrency: string
  savings: CalculatorSavings
}

const FIXED_BANK_LIST = ['pkobp', 'mbank', 'bzwbk']

const MainChartView = ({
  incomingBanksData,
  savings,
  direction,
  value,
  buyCurrency,
  sellCurrency,
}: MainChartViewProps) => {
  const [banksData, setBanksData] = useState<BankData[]>(STATIC_EXCHANGE_RATES)
  const [actualBanksListToDisplay, setActualBanksListToDisplay] = useState<BankData[]>(STATIC_EXCHANGE_RATES)
  const [wtBankData, setWtBankData] = useState<BankData>(STATIC_EXCHANGE_RATES[0])
  const [isOtherBanksData, setIsOtherBanksData] = useState<boolean>(true)

  const sortBankData = (a: BankData, b: BankData): number => Number(a.amount) - Number(b.amount)

  const getBanksDataWithoutWT = (banksData: BankData[]): BankData[] => banksData.filter((bank) => bank.bank_id !== 'wt')

  const getActualBanksList = (banksData: BankData[]) => {
    let actualBanksDataToDisplay = []
    if (banksData.length === 1) {
      setIsOtherBanksData(false)
      return
    }
    const banksDataWithoutWt = getBanksDataWithoutWT(banksData).sort(sortBankData)
    const isWtWorstBank = banksData.sort(sortBankData).slice(0, 1)[0].bank_id === 'wt'
    const worstRateBank = isWtWorstBank ? banksDataWithoutWt.slice(-1) : banksDataWithoutWt.slice(0, 1)
    FIXED_BANK_LIST.forEach((bankId) => {
      const bankData = banksData.find((data) => data.bank_id === bankId)
      if (bankData) actualBanksDataToDisplay = [...actualBanksDataToDisplay, bankData]
    })
    setActualBanksListToDisplay([...actualBanksDataToDisplay, worstRateBank[0]])
  }

  const getWtBankData = (banksData: BankData[]): void => {
    setWtBankData(banksData.find((bank) => bank.bank_id === 'wt'))
  }

  const isForeingBanksData = (banksData: BankData[]): void => {
    setIsOtherBanksData(banksData.length > 1)
  }

  useEffect(() => {
    setBanksData(incomingBanksData)
    getActualBanksList(incomingBanksData)
    getWtBankData(incomingBanksData)
    isForeingBanksData(incomingBanksData)
  }, [incomingBanksData])

  return (
    <div className="calculator-chart__main-chart-holder">
      <div className="calculator-chart__capital-holders">
        <div className="calculator-chart__single-cell is-wide">
          {direction === 'BUY' ? `${i18n('calculator.directionBuy')}` : `${i18n('calculator.directionSell')}`}{' '}
          {formatNumber(value, 2)} {buyCurrency}{' '}
          {direction === 'BUY' ? `${i18n('calculator.payText')}` : `${i18n('calculator.sellText')}`}:
        </div>
        <div className="calculator-chart__single-cell">{i18n('calculator.rateText')}</div>
        <div className="calculator-chart__single-cell">{i18n('calculator.amountText')}</div>
      </div>
      {banksData && (
        <SingleBankRow
          sellCurrency={sellCurrency}
          bankData={wtBankData}
          isFirstRow={true}
        />
      )}
      {isOtherBanksData &&
        actualBanksListToDisplay.map((bank) => (
          <SingleBankRow
            key={bank.bank_id}
            bankData={bank}
            sellCurrency={sellCurrency}
          />
        ))}
      {isOtherBanksData && savings && (
        <InteractiveElements
          banksData={banksData}
          sellCurrency={sellCurrency}
          savings={savings}
        />
      )}
      {!isOtherBanksData && (
        <InfoElement
          sellCurrency={sellCurrency}
          buyCurrency={buyCurrency}
        />
      )}
    </div>
  )
}

export default MainChartView
