export default {
  highcharts: {
    decimalPoint: ',',
    downloadJPEG: 'Pobierz obraz JPEG',
    downloadPDF: 'Pobierz dokument PDF',
    downloadPNG: 'Pobierz obraz PNG',
    downloadSVG: 'Pobierz obraz SVG',
    exportButtonTitle: 'Eksport',
    forexRate: 'Kurs średni FOREX',
    loading: 'Ładowanie...',
    months: [
      'Styczeń',
      'Luty',
      'Marzec',
      'Kwiecień',
      'Maj',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień',
    ],
    printButtonTitle: 'Drukowanie',
    rangeSelectorFrom: 'Od',
    rangeSelectorTo: 'Do',
    rangeSelectorZoom: 'Przybliżenie',
    rateTypes: {
      ask: 'Najlepsza oferta sprzedaży',
      bid: 'Najlepsza oferta kupna',
      forex: 'Średni kurs na świecie',
    },
    resetZoom: 'Reset',
    resetZoomTitle: 'Reset',
    shortMonths: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
    thousandsSep: ' ',
    weekdays: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
    wtBestAsk: 'Najlepsza oferta sprzedaży',
    wtBestBid: 'Najlepsza oferta kupna',
  },
  calculator: {
    title: 'Oblicz oszczędność w Walutomacie',
    rangeFrom: 'Chcę',
    forBuy: 'za',
    forSell: 'na',
    directionBuy: 'Kupując',
    directionSell: 'Sprzedając',
    payText: 'zapłacisz',
    sellText: 'otrzymasz',
    buySelect: 'kupić',
    sellSelect: 'sprzedać',
    rateText: 'Kurs',
    amountText: 'Wartość',
    includes: 'Uwzględnia',
    commission: '{{commission}}% prowizji',
    howItWorks: 'Jak działa kalkulator?',
    banksComapreOpen: 'Porównaj z innymi',
    banksComapreClose: 'Zwiń listę',
    savingsMonthly: 'Oszczędzasz z Walutomatem jednorazowo nawet',
    savingsYearly: 'wymieniając przez cały rok, co miesiąc.',
    conjunction: 'a',
    serverError: 'Błąd komunikacji z serwerem',
    unsupportedPair: 'Aktualnie nie oferujemy kursów dla wybranej pary walutowej.',
    infoError: {
      exchange: 'W Walutomat.pl możesz wymieniać bezpośrednio',
      transactionFee:
        'i odwrotnie. Taka transakcja w banku wiąże się z wysoką opłatą najczęściej sięgającą nawet 5-10% kwoty wymiany.',
    },
    spinnerError: 'Wystąpił problem podczas pobierania danych',
  },
  dashboard: {
    canEditPost: 'Możesz edytować wpis',
    postExists: 'Wpis o takim tytule już istnieje',
    postNotExists: 'Wpis o takim tytule nie istnieje',
    emptyPostTitle: 'Dodaj tytuł wpisu',
    error: 'Coś poszło nie tak :(',
  },
}
