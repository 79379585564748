import { h } from 'preact'
import { useState } from 'preact/hooks'
import { BankData } from 'src/assets/scripts/models/market'
import SingleOption from './single-option'

interface BanksSelectProps {
  banksData: BankData[]
  bankName: string
  handleSetClickedBank: (bankId: string) => void
}

const BanksSelect = ({ handleSetClickedBank, bankName, banksData }: BanksSelectProps) => {
  const [isListVisible, setListVisibility] = useState<boolean>(false)

  const toggleVisibility = (): void => {
    setListVisibility(!isListVisible)
  }

  banksData.sort((a, b) => a.bank_id.localeCompare(b.bank_id))

  return (
    <div>
      <div
        className="calculator-chart__chart-select"
        onClick={toggleVisibility}
      >
        <p>{bankName}</p>
        <span className="calculator-chart__select-chevron" />
      </div>
      {isListVisible && (
        <div
          className="calculator-chart__banks-list"
          onClick={toggleVisibility}
        >
          {banksData.map((bank) => (
            <SingleOption
              key={bank.bank_id}
              bankId={bank.bank_id}
              singleOption={bank.bank_display_name}
              handleSetClickedOption={handleSetClickedBank}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default BanksSelect
