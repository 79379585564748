declare global {
  interface Window {
    CookiebotCallback_OnTagsExecuted?: () => void
  }
}

export const decodeUrlParams = (url: string): { [key: string]: any } => {
  try {
    const { search, hash } = new URL(url)
    const searchParams = Object.fromEntries(new URLSearchParams(search).entries())
    const paramsString = hash.startsWith('#/?') ? hash.slice(2) : ''
    const encodedParams = paramsString ? new URLSearchParams(paramsString).get('data') : null
    let decodedParams = {}

    if (encodedParams) {
      decodedParams = JSON.parse(decodeURIComponent(encodedParams))
      decodedParams = Array.isArray(decodedParams) ? Object.assign({}, ...decodedParams) : decodedParams
    }
    return { ...searchParams, ...decodedParams }
  } catch (error) {
    console.error('Error decoding URL params:', error)
    return {}
  }
}

export const clearUrlParamsIfNoMarketingConsent = (): void => {
  const cleanUrl = window.location.pathname
  const checkConsent = (): void => {
    if (!window.Cookiebot?.consent?.marketing) {
      history.replaceState(null, '', cleanUrl)
    }
  }
  window.CookiebotCallback_OnTagsExecuted = checkConsent
}
