let debounceTimeout

export function debounce(func: () => void, wait: number): void {
  clearTimeout(debounceTimeout)
  debounceTimeout = setTimeout(func, wait)
}

export function throttle(callback: any, wait: number, immediate = false) {
  let timeout = null
  let initialCall = true

  return function (...args: any[]) {
    const callNow = immediate && initialCall
    const next = (): void => {
      callback.apply(this, args)
      timeout = null
    }

    if (callNow) {
      initialCall = false
      next()
    }

    if (!timeout) {
      timeout = setTimeout(next, wait)
    }
  }
}
