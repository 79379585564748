export const dialogHandle = (dialog: HTMLDialogElement, dialogOpener: HTMLElement) => {
  dialog.parentElement.style.position = 'relative'
  dialog.classList.add('tooltip')

  // check if dialog tag is supported
  if (typeof HTMLDialogElement === 'function') {
    dialog.removeAttribute('hidden')
    dialogOpener.addEventListener('mouseenter', () => dialog.show())
    dialogOpener.addEventListener('mouseout', () => dialog.close())
  } else {
    dialog.setAttribute('hidden', '')
    dialogOpener.addEventListener('mouseenter', () => dialog.removeAttribute('hidden'))
    dialogOpener.addEventListener('mouseout', () => dialog.setAttribute('hidden', ''))
  }
}

export const modalHandle = (modal: HTMLDialogElement, modalOpener?: HTMLElement) => {
  const closeBtn = modal.querySelector<HTMLButtonElement>('[data-modal-close]')

  // check if dialog tag is supported
  if (typeof HTMLDialogElement === 'function') {
    modal.removeAttribute('hidden')
    modalOpener?.addEventListener('click', () => modal.showModal())

    modal.addEventListener('click', (e) => {
      const dialogDimensions = modal.getBoundingClientRect()
      if (
        e.clientX < dialogDimensions.left ||
        e.clientX > dialogDimensions.right ||
        e.clientY < dialogDimensions.top ||
        e.clientY > dialogDimensions.bottom
      ) {
        modal.close()
      }
    })

    if (closeBtn) closeBtn.addEventListener('click', () => modal.close())
  } else {
    modal.setAttribute('hidden', '')
    modalOpener?.addEventListener('click', () => modal.removeAttribute('hidden'))

    modal.addEventListener('click', (e) => {
      const dialogDimensions = modal.getBoundingClientRect()
      if (
        e.clientX < dialogDimensions.left ||
        e.clientX > dialogDimensions.right ||
        e.clientY < dialogDimensions.top ||
        e.clientY > dialogDimensions.bottom
      ) {
        modal.setAttribute('hidden', '')
      }
    })

    if (closeBtn) closeBtn.addEventListener('click', () => modal.setAttribute('hidden', ''))
  }
}
