import { h } from 'preact'
import { useState } from 'preact/hooks'
import SingleChart from './single-chart'
import { BankData } from 'src/assets/scripts/models/market'
import { formatNumber } from 'utils/format-number'

interface BankOptionsElementProps {
  banksData: BankData[]
  sellCurrency: string
}

const BankOptionsElement = ({ banksData, sellCurrency }: BankOptionsElementProps) => {
  const worstRateBank = banksData[banksData.length - 1].bank_id

  const [selectedBank, setSelectedBank] = useState<string>(worstRateBank)

  const selectedBankData = banksData.find((bank) => bank.bank_id === selectedBank)

  const setClickedBank = (clickedBank: string): void => {
    const clickedBankId = banksData.find((bank) => bank.bank_id === clickedBank)
    if (clickedBankId) setSelectedBank(clickedBankId.bank_id)
  }

  return (
    <div className="calculator-chart__single-chart-row">
      <div className="calculator-chart__single-cell is-wide">
        {selectedBankData && (
          <SingleChart
            isBankSelect={true}
            banksData={banksData}
            bankName={selectedBankData.bank_display_name}
            offerPercentage={selectedBankData.offer_percent}
            handleSetClickedBank={setClickedBank}
          />
        )}
      </div>
      <div className="calculator-chart__single-cell">
        <div className="calculator-chart__row-value">{formatNumber(selectedBankData.rate, 4)}</div>
      </div>
      <div className="calculator-chart__single-cell">
        <div className="calculator-chart__row-value">
          {formatNumber(selectedBankData.amount, 2)} {sellCurrency}{' '}
        </div>
      </div>
    </div>
  )
}

export default BankOptionsElement
