import { h, Component } from 'preact'
import MainCalculatorView from 'common-modules/calculator/calculator-main'

export default class MainCalculatorApp extends Component {
  public render() {
    return (
      <div id="preact_root">
        <MainCalculatorView />
      </div>
    )
  }
}
