import i18next, { TFunctionDetailedResult } from 'i18next'
import { SupportedLanguages, getCurrentLanguage } from 'utils/current-language'
import en from '../i18n/languages/en'
import pl from '../i18n/languages/pl'
import uk from '../i18n/languages/uk'

export const initI18n = () => {
  const addResourceBundle = (language: string, translations) => {
    i18next.addResourceBundle(language, 'translation', translations)
  }

  return i18next
    .init({
      lng: getCurrentLanguage(),
      fallbackLng: SupportedLanguages.pl,
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      resources: {
        en: {
          translation: en,
        },
        pl: {
          translation: pl,
        },
        uk: {
          translation: uk,
        },
      },
      nsSeparator: '::',
    })
    .then(() => {
      switch (getCurrentLanguage()) {
        case 'en':
          return import('./languages/en').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        case 'ru':
          return import('./languages/ru').then((translations) => {
            addResourceBundle(getCurrentLanguage(), translations.default)
          })
        default:
          return import('./languages/pl').then((translations) => {
            addResourceBundle('pl', translations.default)
          })
      }
    })
}

export const i18n = (key, props?): TFunctionDetailedResult => i18next.t(key, props)

export const getTranslationsMap = (): any => i18next.getResourceBundle(getCurrentLanguage(), '')
